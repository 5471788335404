import React from "react";

function Footer(){
    return(
        <div class="text-center p-6 bg-black footer">
        <span class="text-white  font-bold">&copy; 2024 Copyright - Huzaifa Mahmood</span>
      </div>
    )
}


export default Footer
